import { useEffect, useState } from "react";
import { FiTrash } from "react-icons/fi";
import { Navigate, useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap";
import user1 from "../../../assets/me.jpg";
import user2 from "../../../assets/me.jpg";
import user3 from "../../../assets/me.jpg";
import user4 from "../../../assets/me.jpg";
import user5 from "../../../assets/me.jpg";
import CustomButton from "../../../components/button/CustomButton";
import Spinner from "../../../components/spinner/Spinner";
import useToasts from "../../../hooks/useToasts";
import { deleteUserId, getAllUsers } from "../../../services/users";
import "./clientsTable.css"
import FormClient from "./components/FormsClient";
//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';







const ClientTable = () => {

  const [filter, setFilter] = useState({stateParam: 'ACTIVE',typeParam: 'CLIENT', showRows: 20});
  const [users, setUsers] = useState(null);
  const { addToast } = useToasts();
  const [showForm, setShowForm] = useState(false);

  let navigate = useNavigate(); 
  const routeChange = (path) =>{ 
    navigate(path);
  }

  var type = 0;

  var id = null;



  // const showForm = () => {
  //   setShowForm(!showForm);
  // }


  const createUser = () => {
    setShowForm(!showForm);
  }

  const editUser = (ids) =>{
    type = 1;
    id = ids;
    setShowForm(!showForm);

  }

  const showEditForm = ()=>{
    return (
      <FormClient type={type} id={id}></FormClient>
    )
  }


  const deleteUser = (data) => {


    // withReactContent(Swal).fire({
    //   title: "Esta seguro de eliminar este cliente?",
    //   text: data.userName,
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonText: "Si",
    //   cancelButtonText: "No",
    //   reverseButtons: true
    // })

    // deleteUserId(data.id);

    // addToast({
    //   type: 'success',
    //   title: 'Hecho',
    //   message: 'Cliente eliminado',
    // })

    // setFilter({stateParam: 'ACTIVE',typeParam: 'CLIENT', showRows: 20});


  }






  useEffect(async () => {
    window.scrollTo(0, 0);

    let res = await getAllUsers(filter);

    setUsers(res.data.content);


  }, [filter]);



  return (
    <div>
      {!showForm ? (
        <Card>
          <CardBody>
            <div className="row">
            {/* <CardTitle className="d-flex " tag="h5">Clientes</CardTitle> */}
            <div
              className="catalog__create_form-container__fields"
              
            >
              
              <button className="align-items-center" style={{'margin-right': '4rem'}} onClick={()=> createUser()} >+ Crear</button>
            </div>
            </div>

            <CardSubtitle className="mb-2 text-muted" tag="h6">
              
            </CardSubtitle>

            <Table className="no-wrap mt-3 align-middle" responsive borderless>
              <thead>
                <tr>
                  <th>Usuario</th>
                  <th>RIF</th>

                  <th>Status</th>
                  <th>Ordenes</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {users ? (
                 users.map((tdata, index) => (
                  <tr key={index} className="border-top">
                    <td>
                      <div className="d-flex align-items-center p-2">
                        <img
                          src={user1}
                          className="rounded-circle"
                          alt="avatar"
                          width="45"
                          height="45"
                        />
                        <div className="ms-3">
                          <h6 className="mb-0">{tdata.name} {tdata.lastNames}</h6>
                          <span className="text-muted">{tdata.username}</span>
                        </div>
                      </div>
                    </td>
                    <td>J8876879-2</td>
                    <td>
                      {tdata.status === "pending" ? (
                        <span className="p-2 bg-danger rounded-circle d-inline-block ms-3"></span>
                      ) : tdata.status === "holt" ? (
                        <span className="p-2 bg-warning rounded-circle d-inline-block ms-3"></span>
                      ) : (
                        <span className="p-2 bg-success rounded-circle d-inline-block ms-3"></span>
                      )}
                    </td>
                    <td> 0 </td>
                    <td>
                      <div className="orders-actions">

                      <i className={"d-inline-flex btn btn-info bi bi-key"}></i> 
                      <i className={"d-inline-flex btn btn-info bi bi-pencil"} onClick={()=> routeChange('/dashboard/user/edit/'+tdata.id)}></i>  
                      <i className={"d-inline-flex btn btn-info bi bi-eye"} ></i> 
                      <i className={"d-inline-flex btn btn-danger bi bi-trash"} onClick={()=> deleteUser(tdata)}></i>

                      </div>
                    </td>
                  </tr>
                ))
                
                ): null
              }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      ):(
         showEditForm()
      )
      
      }
    </div>
  );
};

export default ClientTable;
