import { AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Route, Routes, Navigate, useRoutes, HashRouter } from "react-router-dom";
import Footer from "./container/footer/Footer";
import Header from "./container/header/Header";
import HomePage from "./container/pages/homepage/HomePage";
import MembershipPage from "./container/pages/membership/MembershipPage";
import SubscribePage from "./container/pages/subscribe/SubscribePage";
import ContactPage from "./container/pages/contact/ContactPage";
import SignUpPage from "./container/pages/signup/SignUpPage";
import SignInPage from "./container/pages/signin/SignInPage";
import BlogPage from "./container/pages/blog/BlogPage";
import AuthorPage from "./container/pages/author/AuthorPage";
import AuthorDetails from "./container/pages/authorDetails/AuthorDetails";
import TagPage from "./container/pages/tags/TagPage";
import ProductsPage from "./container/pages/products/productsPage";
import TagDetails from "./container/pages/tagDetails/TagDetails";
//import ProductDetails from "./container/pages/productDetails/productDetails";
import NotFound from "./container/notfound/NotFound";
import Features from "./container/pages/features/Features";
import AuthProvider from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';
import { ToastsProvider } from './context/ToastsContext';

import "./App.scss";
import AboutUs from "./container/pages/about/AboutUs";
import { ProductsProvider } from "./context/ProductsContext";
import ProductPage from "./container/pages/product/ProductPage";
import BtnShoppingCart from "./components/btn-shopping-cart/BtnShoppingCart";
import { ShoppingCartProvider } from "./context/ShoppingCartContext";
import ShoppingCartPage from "./container/pages/shoppingCart/ShoppingCartPage";
import OrdersPage from "./container/dashboard/orders/OrdersPage";
import DashboardRouting from "./container/dashboard/dashboard.routing";
import FullLayout from "./layout/FullLayout";
import PreviewOrders from "./container/dashboard/orders/preview-orders/PreviewOrders";
import ClientsPage from "./container/dashboard/clients/ClientsPage";
import PageLayout from "./layout/PageLayout";
import { GenerateOrderProvider } from "./context/GenerateOrderContext";
import FormsClient from "./container/dashboard/clients/components/FormsClient";

function App() {


  return (
    // <Router>
      <div className="main-p">
        <AnimatePresence>
        <ThemeProvider>
        
        <ProductsProvider>
          <AuthProvider>
          <GenerateOrderProvider>
          <ShoppingCartProvider>
          <ToastsProvider>
          
          <Router  basename={process.env.REACT_APP_CONTEXT || '/'}>
            <Routes>

              <Route exact path="/subscribe" element={<SubscribePage></SubscribePage>} />
              <Route exact path="/contact" element={<ContactPage></ContactPage>} />
              <Route exact path="/signup" element={<SignUpPage></SignUpPage>} />
              <Route exact path="/signin" element={<SignInPage></SignInPage>} />
              <Route exact path="/" element={<Navigate to='/products'/>} />
              
              
              <Route  basename={process.env.REACT_APP_CONTEXT || '/'} element={<PageLayout/>}>
                {/* <Header /> */}
                {/* <BtnShoppingCart /> */}
                  <Route path="/home" element={<HomePage></HomePage>} />
                  <Route exact path="/authors" element={<AuthorPage></AuthorPage>} />
                  <Route
                    exact
                    path="/author/:authorName"
                    component={AuthorDetails}
                  />
                  {/* <Route exact path="/membership" component={MembershipPage} />
                  <Route exact path="/features" component={Features} />
                  <Route exact path="/about" component={AboutUs} />
                  <Route exact path="/article/:id" component={BlogPage} />
                  <Route exact path="/tags" component={TagPage} />
                  <Route exact path="/tag/:tagName" component={TagDetails} /> */}
                  <Route exact path="/product/:id" element={<ProductPage></ProductPage>} />
                  <Route exact path="/products" element={<ProductsPage></ProductsPage>} />
                  <Route exact path="/products/:categoryName" element={<ProductsPage></ProductsPage>} />
                  <Route exact path="/shoppingcart" element={<ShoppingCartPage></ShoppingCartPage>} />

                    <Route path="*" element={<NotFound></NotFound>} />


                {/* <Footer /> */}
              </Route>

              <Route path="/dashboard" element={<FullLayout/>}>
                    <Route exact path="clientes" element={<ClientsPage></ClientsPage>}></Route>
                    <Route exact path="user/edit/:id" element={<FormsClient></FormsClient>}></Route>
                    <Route exact path="ordenes" element={<PreviewOrders></PreviewOrders>}></Route>

                    {/* <Route path="*" element={<NoPage />} /> */}
                  </Route>
              {/* <Router >
                <Header />
                <Routes>

                </Routes>

              </Router> */}
            </Routes>
            </Router>
            </ToastsProvider>
            </ShoppingCartProvider>
            </GenerateOrderProvider>
            </AuthProvider>
            </ProductsProvider>
            
          </ThemeProvider>
        </AnimatePresence>
      </div>
    // </Router>
  );
}

export default App;
