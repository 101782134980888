import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./previewShippingMethod.css";

import { ShoppingCartContext } from "../../context/ShoppingCartContext";
import { FiDelete } from "react-icons/fi";
import { FaTimes, FaWindowClose } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import configData from "../../config/configData";
import { AuthContext } from "../../context/AuthContext";
import useToasts from "../../hooks/useToasts";
import { GenerateOrderContext } from "../../context/GenerateOrderContext";
import { Form, Col, Input, Label, Row, FormGroup } from "reactstrap";


const PreviewShippingMethod = ({ name, url, link }) => {

  const {incrementStep,decreaseStep} = useContext(GenerateOrderContext);
  const { addToast } = useToasts();
  const {user} = useContext(AuthContext);

  const { addNewOrders,formDataShipping,setFormDataShipping } = useContext(ShoppingCartContext);



  const changeFormShippingM = (e) => {
    setFormDataShipping({
      ...formDataShipping,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <div className="okiro__seealso">
      <h2>Datos de envío</h2>
      <aside className="okiro__seealso product-detail">
     
          <div className="my-order-content">


          <Form onChange={changeFormShippingM}>

          <Row>

            <Col md={12}>

            <FormGroup>
                    <Label for="exampleUserName">Tipo de envío</Label>
                    <Input id="methodShipping" name="methodShipping" type="select">
                      <option value={'RP'}>Retirar personalmente</option>
                      <option value={'ED'}>Enviar a domicilio</option>
                    </Input>
            </FormGroup>

            </Col>
          </Row>

          { formDataShipping.methodShipping != 'RP' ? (
            <>

                    <Row>

                    <Col md={6}>
                    <FormGroup>
                        <Label for="name">Nombre</Label>
                        <Input
                          name="name"
                          type="text"
                          value={formDataShipping.name}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                    <FormGroup>
                        <Label for="lastNames">Apellido</Label>
                        <Input
                          name="lastNames"
                          type="text"
                          value={formDataShipping.lastNames}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md={6}>
                      <FormGroup>
                      <Label for="name">C.I</Label>
                      <Input
                        name="docID"
                        type="text"
                        placeholder="Ej: V25682852"
                        value={formDataShipping.docID}
                      />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                    <FormGroup>
                    <Label for="phone">Teléfono</Label>
                    <Input
                      name="phone"
                      type="text"
                      placeholder="Ej: 0424-0000000"
                      value={formDataShipping.phone}
                    />
                    </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md={12}>
                      <FormGroup>
                          <Label for="address">Dirección</Label>
                          <Input id="address" name="address" type="textarea" 
                          value={formDataShipping.address}/>
                        </FormGroup>
                    </Col>
                    </Row>


            </>
          ) :null
          }

            
          </Form>




              <div
                className="okiro__custom-button shopping__button_products__fields_old"
               
              >
                
                <button onClick={()=>decreaseStep()}  > Atras </button>
              </div>

              <div
                className="okiro__custom-button shopping__button_products__fields ml-2"
               
              >
                
                <button onClick={()=>incrementStep()}  > Continuar </button>
              </div>
          </div>

        </aside>
    </div>
  );
};

export default PreviewShippingMethod;
