export function getDateFormat(date){

    const dateCreate = new Date(date);

    // const dateFormatted = dateCreate.toLocaleDateString('es-VE', {
    // timeZone: 'UTC',
    // });

    const dateFormatted = new Intl.DateTimeFormat('es-VE', {
        dateStyle: "short",
        timeStyle: "short",
        timeZone: 'America/La_Paz',
    });

    return dateFormatted.format(dateCreate);

}