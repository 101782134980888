import React,{ createContext,useContext,useEffect,useState } from "react";

export const GenerateOrderContext = createContext();

export const GenerateOrderProvider = ({ children }) => {  
  const [generateOrder, setGenerateOrder] = useState([]);
  const [step, setStep] = useState(0);


  const incrementStep = () => {

    setStep(step+1);

  }

  const decreaseStep = () => {

    setStep(step-1);

  }

  const resetStep = () =>{
    setStep(0);
  }


  useEffect(() => {
  }, [])



  const contextValue = {
    step,
    setStep,
    incrementStep,
    decreaseStep,
    resetStep
  }
 

  return(
    <GenerateOrderContext.Provider value={contextValue}>
      {children}
    </GenerateOrderContext.Provider>
  )
  
}