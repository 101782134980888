import React,{ createContext,useContext,useEffect,useState } from "react";
//import useToasts from "../hooks/useToasts";
import { getAllProducts } from "../services/Products";
import { AuthContext } from "./AuthContext";
import { createNewOrders } from "../services/Orders";

//import useFetchOrders from "../hooks/useFetchOrders";

export const ShoppingCartContext = createContext();

export const ShoppingCartProvider = ({ children }) => {  
  const [shoppingCart, setShoppingCart] = useState([]);
  const [shoppingCartTotal, setShoppingCartTotal] = useState({ totalPrice: 0, totalWeight: 0 });
  const [productsCount, setProductsCount] = useState(0);

  const [formDataShipping,setFormDataShipping] = useState(
    {
      name: '',
      lastNames: '',
      phone: '',
      email: '',
      methodShipping: 'RP',
      address: '',
      docID: '',
      state: 'ACTIVE'
    }
  );

  const [formDataPayment,setFormDataPayment] = useState(
    {
      methodPayment: 'TB',
    }
  );

  const [createOrder, setCreateOrder] = useState(false);

  const {user} = useContext(AuthContext);

  //const {setOrders} = useFetchOrders();

  //const { addToast } = useToasts();

  const resetShoppingCart = () => {
    setShoppingCart([]);
    setShoppingCartTotal({ totalPrice: 0, totalWeight: 0 });
    setProductsCount(0);
    setFormDataShipping( {
      name: '',
      lastNames: '',
      phone: '',
      email: '',
      methodShipping: 'RP',
      address: '',
      docID: '',
      state: 'ACTIVE'
    });
    setFormDataPayment(
      {
        methodPayment: 'TB',
      }
    );


  }


  const incrementShoppingCart = () => {

    setProductsCount(productsCount+1);

  }

  const decreaseShoppingCart = () => {

    setProductsCount(productsCount-1);

  }

  const addShoppingCart = (productDta, amount) => {

    let shoppingNew = shoppingCart;

    let totalPriceProd = 0;
    let totalWeight = 0;

    if(shoppingCart.findIndex((item) => item.product.id === productDta.id) < 0 || shoppingCart.length == 0){

      console.log('productDta: ', productDta);

      incrementShoppingCart();

      totalPriceProd = parseFloat(productDta.unitPrice) *  amount ;

      shoppingNew.push({product:productDta , amount: amount, unitPrice: productDta.unitPrice, totalPrice: totalPriceProd , unitWeight: 0, totalWeight: 0 });

      setShoppingCartTotal({ totalPrice: shoppingCartTotal.totalPrice + totalPriceProd , totalWeight: shoppingCartTotal.totalWeight + totalWeight });

      setShoppingCart(shoppingNew);

      // addToast({
      //   type: 'success',
      //   title: 'Hecho',
      //   message: 'Artículo agregado al carrito',
      // })

      console.log('Products en el carrito: ', shoppingCart)

      return true;
    }

    return false;
  }

  const addNewOrders = async () =>{

     let orderNew = {
      "totalWeight": shoppingCartTotal.totalWeight,
      "totalPrice": shoppingCartTotal.totalPrice,
      "comment": "",
      "methodShipping": formDataShipping,
      "methodPayment": formDataPayment.methodPayment,
      
      "creatorName": '',
      "user": {
        "id": user.id,
      },
      "state": "LOADED",
      "products": shoppingCart
     }

     //setOrders(orderNew);


     try {

      const res = await createNewOrders(orderNew);

       resetShoppingCart();

        //setResponse(res.data);
      //  if(res.data.empty || res.data.last) setLastPage(true);
      } catch (error) {
        console.error(error);
      } finally{
       // setLoading(false);
      }

  }



    const deleteShoppingCart = (productDta) => {

      let shoppingNew = shoppingCart;
  
      if(shoppingCart.findIndex((item) => item.product.code === productDta.code) >= 0){
  
        
        setShoppingCartTotal({ totalPrice: shoppingCartTotal.totalPrice - shoppingCart.find((item) => item.product.code === productDta.code).product.totalPrice , totalWeight: 0 })
  
        decreaseShoppingCart();
  
        shoppingNew = shoppingNew.filter((item) => item.product.code !== productDta.code);
  
        setShoppingCart(shoppingNew);

        // addToast({
        //   type: 'success',
        //   title: 'Hecho',
        //   message: 'Artículo eliminado del carrito',
        // })
  
        console.log('Products en el carrito: ', shoppingCart)

        return true;
  
  
      }

      return false;

    


  }


  useEffect(() => {
  }, [])



  const contextValue = {
    shoppingCart,
    shoppingCartTotal,
    createOrder,
    setCreateOrder,
    addNewOrders,
    setShoppingCart,
    addShoppingCart,
    deleteShoppingCart,
    incrementShoppingCart,
    decreaseShoppingCart,
    formDataShipping,
    setFormDataShipping,
    formDataPayment,
    setFormDataPayment,
    productsCount,
  }
 

  return(
    <ShoppingCartContext.Provider value={contextValue}>
      {children}
    </ShoppingCartContext.Provider>
  )
  
}