import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import "./previewShoppingCart.css";

import { ShoppingCartContext } from "../../context/ShoppingCartContext";
import { FiDelete } from "react-icons/fi";
import { FaTimes, FaWindowClose } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import configData from "../../config/configData";
import { AuthContext } from "../../context/AuthContext";
import useToasts from "../../hooks/useToasts";
import { GenerateOrderContext } from "../../context/GenerateOrderContext";


const PreviewShoppingCart = ({ name, url, link }) => {


  const {shoppingCart, deleteShoppingCart } = useContext(ShoppingCartContext);
  const {incrementStep} = useContext(GenerateOrderContext);
  const { addToast } = useToasts();
  const {user} = useContext(AuthContext);

  const { addNewOrders } = useContext(ShoppingCartContext);




  const generateOrder = () =>{

    if(user){

      addToast({
        type: 'success',
        title: 'Hecho',
        message: 'Se ha generado la orden con éxito',
      })

      
      addNewOrders();
      
     window.location.href=process.env.REACT_APP_CONTEXT ? process.env.REACT_APP_CONTEXT+'/dashboard/orders' : '/dashboard/orders';

    } else{

     // return redirect("/signin");

     window.location.href=process.env.REACT_APP_CONTEXT ? process.env.REACT_APP_CONTEXT+'/signin' : '/signin';

    }



  }

  const deleteProduct = (product) =>{


    if(deleteShoppingCart(product)){

      addToast({
        type: 'success',
        title: 'Hecho',
        message: 'Artículo eliminado del carrito',
      })

    }


  }

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])


  return (
    <div className="okiro__seealso">
      <h2>Carrito de compra</h2>
      <aside className="okiro__seealso product-detail">
     
          <div className="my-order-content">

          { shoppingCart ? 
        shoppingCart.map((item) => (
          <div class="shopping-cart">
              <figure>
                <img src={item.product.imageUrl} alt=""/>
              </figure>
              <p>{item.product.name}</p>
              <p>{item.amount}</p>
              <p>{configData.currencyFormat} {item.product.unitPrice}</p>
              <p className="del_shopping" title="Eliminar del carrito" onClick={() => deleteProduct(item.product)}><FaTimes/></p>
            </div>



            ))
            : null
          }
              <div
                className="okiro__custom-button shopping__button_products__fields"
               
              >
                
                <button onClick={()=>incrementStep()}  > Continuar </button>
              </div>
          </div>

        </aside>
    </div>
  );
};

export default PreviewShoppingCart;
