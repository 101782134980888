import React, { useContext, useEffect, useState } from "react";
import { IoLockClosed } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";
import Tag from "../tag/Tag";

import configData from "../../config/configData";

import "./latestProduct.css";
import CustomButton from "../button/CustomButton";
import { FiPlus } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa6";
import { ShoppingCartContext } from "../../context/ShoppingCartContext";
import $ from 'jquery';
import useToasts from "../../hooks/useToasts";

const LatestProduct = ({data, showAllTags}) => {

  const [amount, setAmount] = useState(1);
  const { addToast } = useToasts();
  const {setShoppingCart,addShoppingCart,decreaseShoppingCart,productsCount} = useContext(ShoppingCartContext);

  function randomDate(start, end) {
    const options = { year: "numeric", month: "long", day: "numeric" };

    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    ).toLocaleDateString("en-US", options);
  }

  const date = randomDate(new Date(2012, 0, 1), new Date());

  // $('.input-number-increment').click(function() {
  //   var $input = $(this).parents('.input-number-group').find('.input-number');
  //   var val = parseInt($input.val(), 10);
  //   $input.val(val + 1);
  // });
  
  // $('.input-number-decrement').click(function() {
  //   var $input = $(this).parents('.input-number-group').find('.input-number');
  //   var val = parseInt($input.val(), 10);
  //   $input.val(val - 1);
  // })

  const inputIncrement = () =>{
    console.log('increment')
    setAmount( amount + 1);
  }

  const inputDecrement = () =>{
    if(amount > 1){

      setAmount(amount - 1);

    }
  }


 


  console.log('LatestProduct');


  const addProdShoppingCart = () => {
    if(addShoppingCart(data,amount)){
      addToast({
        type: 'success',
        title: 'Hecho',
        message: 'Artículo agregado al carrito',
      })
    }else{

      addToast({
        type: 'success',
        title: 'Hecho',
        message: 'Artículo ya en el carrito',
      })

    }
  }

  useEffect(()=>{

  },[amount])
  
  


  return (
    <div className="okiro__homepage__latest">

      <div className="okiro__homepage__latest-img">
        <Link to={{ pathname: `/product/${data.id}` }}>
          <img src={data.imageUrl} alt={data.name} />
        </Link>
      </div>

      <div className="okiro__homepage__latest-content">

        <div className="okiro__homepage__latest-content_tags">

          {showAllTags ? (
              <Tag title={data.category.nombre}  />
              
          ) : null 
          }

        <div style={{'margin-left':'auto'}}><i class="fa fa-star start-sty"></i></div>
          
        </div>


        <Link to={{ pathname: `/product/${data.id}` }} style={{'margin-top':'auto'}}>
          <h2 >{data.name}</h2>
        </Link>

        <div className="okiro__homepage__latest-content_shortpara">


          <div class="product-price">{configData.currencyFormat} {data.unitPrice}</div>

        </div>

        <div className="okiro__homepage__latest-content_short">

              <div  className="text-center" >
                  <h4 class="text-center" style={{'margin-bottom':'5px'}}>Cantidad</h4>
                  <div class="input-group input-number-group">
                    <button class="input-group-button border-0" onClick={()=>inputDecrement()}>
                      <span class="input-number-decrement" >-</span>
                    </button>
                    <input class="input-number" type="number" value={amount} min="1" max="20"/>
                    <button class="input-group-button border-0" onClick={()=>inputIncrement()}>
                      <span class="input-number-increment" >+</span>
                    </button>
                  </div>
              </div>

              {/* <div
                className="okiro__custom-button okiro__button_products__fields"
               
              >
                
                <button > <FiPlus /> Añadir</button>
              </div> */}

        </div>

        <div className="latest-product-footer">
              <div
                className="okiro__custom-button okiro__button_products__fields"
               
              >
                
                <button onClick={()=> addProdShoppingCart()} > <FiPlus /> Añadir artículo</button>
              </div>

              <div
                className="okiro__custom-button  okiro__button_products__fields"
               
              >
                
                <button className="btn-whats"  > <IoLogoWhatsapp /> Pedir por WhatsApp</button>
              </div>
        </div>

        


      </div>
    </div>
  );
};

export default LatestProduct;
