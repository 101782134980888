import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import subscribe from "../../assets/subscribe.jpeg";
import contact from "../../assets/contact.jpeg";
import signin from "../../assets/signin.jpg";
import signup from "../../assets/signup.jpeg";
//import okiro from "../../assets/okiro.svg";
//import logo from "../../assets/images/logo512.png";
import  logo  from "../../assets/images/logoM.png";
import CustomButton from "../button/CustomButton";
import { useAuth } from "../../context/AuthContext";
import useToasts from '../../hooks/useToasts'

import "./connectWithUs.css";

const ConnectWithUs = ({
  title,
  subtitle,
  btnName,
  nameField,
  textareaField,
  imageUrl,
  sign,
}) => {
  const { login } = useAuth()
  const { addToast } = useToasts()
  let url;
  if (imageUrl === "subscribe") url = subscribe;
  else if (imageUrl === "signin") url = signin;
  else if (imageUrl === "signup") url = signup;
  else url = contact;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [inputsLogin, setinputsLogin] = useState({
    username: '',
    password: '',
  })

  const changeForm = (e) => {
    setinputsLogin({
      ...inputsLogin,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
   // setShowLoading(true)
    try {
      if (!(await login(inputsLogin))) {
        addToast({
          type: 'error',
          title: 'Upss !!',
          message: 'Debe verificar las credenciales',
        })
      } else{
        console.log('redirect : /home');
        window.location.href=process.env.REACT_APP_CONTEXT ? process.env.REACT_APP_CONTEXT+'/dashboard' : '/dashboard';


        
      }
      //setShowLoading(false)
    } catch (error) {
      //setShowLoading(false)
      console.error(error)
    }
  }

  return (
    <div className="okiro__connectwithus">
      <div className="okiro__connectwithus_image-container">
        <img src={url} alt="subscribe" />
      </div>
      <div className="okiro__connectwithus_form-container">
        <div className="okiro__connectwithus_form-container__logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="okiro__connectwithus_form-container__heading">
          <h1>
            {title}
            {subtitle ? (
              <>
                <br />
                {subtitle}
              </>
            ) : null}
          </h1>
        </div>
        <form className="okiro__connectwithus_form-container__fields" onChange={changeForm} onSubmit={handleSubmit}>
        
          {nameField ? (
            <div className="okiro__connectwithus_form-container__fields-input">
              <p>Your Name</p>
              <input type="text" />
            </div>
          ) : null}

          <div className="okiro__connectwithus_form-container__fields-input">
            <p>Usuario</p>
            <input type="email" name="username" />
          </div>
          
          <div className="okiro__connectwithus_form-container__fields-input">
            <p>Contraseña</p>
            <input type="password" name="password" />
          </div>

          {sign ? (
            <div>
            {sign === "signup" ? (
              <div className="okiro__connectwithus_form-container__fields-input">
              <p>Confirmar Contraseña</p>
              <input type="cpassword" />
              </div>
            ): null }
            </div>
                     
  
           ) : null}

          {textareaField ? (
            <div className="okiro__connectwithus__form-container__fields-textarea">
              <p>Your Message</p>
              <textarea rows={5} />
            </div>
          ) : null}

          {sign ? (
            <div className="okiro__connectwithus_form-container__fields-sign">
              {sign === "signup" ? (
                <p>
                  Ya tienes una cuenta?{" "}
                  <Link to="/signin">
                    <span>Iniciar sessión</span>
                  </Link>
                </p>
              ) : null
              // (
              //   // <p>
              //   //   Aún no tienes una cuenta?{" "}
              //   //   <Link to="/signup">
              //   //     <span>Registrate</span>
              //   //   </Link>
              //   // </p>
              // )
              }
            </div>
          ) : null}
        
              
        <div className="okiro__connectwithus_form-container__fields-input">


            <CustomButton title={btnName} />
          
        </div>
        

        </form>
      </div>
    </div>
  );
};

export default ConnectWithUs;
