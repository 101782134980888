import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import "./previewPaymentMethod.css";

import { ShoppingCartContext } from "../../context/ShoppingCartContext";
import { FiDelete } from "react-icons/fi";
import { FaTimes, FaWindowClose } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import configData from "../../config/configData";
import { AuthContext } from "../../context/AuthContext";
import useToasts from "../../hooks/useToasts";
import { GenerateOrderContext } from "../../context/GenerateOrderContext";
import { Form, Col, Input, Label, Row, FormGroup } from "reactstrap";


const PreviewPaymentMethod = ({ name, url, link }) => {

  const {incrementStep,decreaseStep} = useContext(GenerateOrderContext);
  const { addToast } = useToasts();
  const {user} = useContext(AuthContext);

  const { addNewOrders,formDataPayment,setFormDataPayment  } = useContext(ShoppingCartContext);


  const changeFormPaymentM = (e) => {
    setFormDataPayment({
      ...formDataPayment,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])


  return (
    <div className="okiro__seealso">
      <h2>Datos de Pago</h2>
      <aside className="okiro__seealso product-detail">
     
          <div className="my-order-content">


          <Form onChange={changeFormPaymentM}>

              <Row>

                <Col md={12}>

                <FormGroup>
                        <Label for="exampleUserName">Método de pago</Label>
                        <Input id="methodPayment" name="methodPayment" type="select">
                          <option value={'TB'}>Transferencia bancaria</option>
                          <option value={'PM'}>Pago móvil</option>
                          <option value={'EF'}>Efectivo</option>
                        </Input>
                </FormGroup>

                </Col>
              </Row>
          </Form>






              <div
                className="okiro__custom-button shopping__button_products__fields_old"
               
              >
                
                <button onClick={()=>decreaseStep()}  > Atras </button>
              </div>

              <div
                className="okiro__custom-button shopping__button_products__fields ml-2"
               
              >
                
                <button onClick={()=>incrementStep()}  > Continuar </button>
              </div>
          </div>

        </aside>
    </div>
  );
};

export default PreviewPaymentMethod;
