import React from "react";

import "./customButton.css";

const CustomButton = ({ title, click, styles, stylesFix }) => {
  return (
    <>
    
    <div
      className="okiro__custom-button"
      style={stylesFix ? { ...stylesFix } : null}
      onClick={click ? () => click() : null}
    >

      <button style={styles ? { ...styles } : null}>{title}</button>
    </div>

    </>
  );
};

export default CustomButton;
