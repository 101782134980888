import { Outlet } from "react-router-dom";
import Sidebar from "../container/Sidebar/Sidebar";
import Header from "../container/header/Header";
import { Container } from "reactstrap";
import Footer from "../container/footer/Footer";

const PageLayout = () => {
  return (
    <>
      {/********header**********/}
      <Header />
        {/********Content Area**********/}
        <div>
          {/********Middle Content**********/}
          <Container className="" fluid>
            <Outlet />
          </Container>
        </div>
        <a href="https://api.whatsapp.com/send?phone=584127964078&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20catalogo%20de%20productos." class="float" target="_blank">
          <i class="bi bi-whatsapp my-float"></i>
        </a>

      <Footer />
      
    </>
  );
};

export default PageLayout;
