import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./previewGenerateOrder.css";

import { ShoppingCartContext } from "../../context/ShoppingCartContext";
import { FiDelete } from "react-icons/fi";
import { FaTimes, FaWindowClose } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import configData from "../../config/configData";
import { AuthContext } from "../../context/AuthContext";
import useToasts from "../../hooks/useToasts";
import { GenerateOrderContext } from "../../context/GenerateOrderContext";


const PreviewGenerateOrder = ({ name, url, link }) => {

  const {incrementStep,decreaseStep,resetStep} = useContext(GenerateOrderContext);
  const { addToast } = useToasts();
  const {user} = useContext(AuthContext);

  let navigate = useNavigate();

  const { addNewOrders,formDataShipping,formDataPayment, shoppingCart, shoppingCartTotal } = useContext(ShoppingCartContext);



  const generateOrder = () =>{

    if(user){

      addToast({
        type: 'success',
        title: 'Hecho',
        message: 'Se ha generado la orden con éxito',
      })

      
      addNewOrders();
      setTimeout(() => {
        //window.location.href=process.env.REACT_APP_CONTEXT ? process.env.REACT_APP_CONTEXT+'/dashboard/ordenes' : '/dashboard/ordenes';
        resetStep();
        navigate('/dashboard/ordenes');
      },1000);

    } else{

     // return redirect("/signin");

     navigate('/signin');

    // window.location.href=process.env.REACT_APP_CONTEXT ? process.env.REACT_APP_CONTEXT+'/signin' : '/signin';

    }



  }




  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <div className="okiro__seealso">
      <h2>Información de la Orden</h2>
     
      <div class="row" style={{ "width":"90%", justifyContent:"center"}}>
        <div class="col-xl-8">

            <div class="card">
                <div class="card-body">
                    <ol class="activity-checkout mb-0 px-4 mt-3">
                        <li class="checkout-item">
                            <div class="avatar checkout-icon p-1">
                                <div class="avatar-title rounded-circle bg-primary">
                                    <i class="bx bxs-receipt text-white font-size-20"></i>
                                </div>
                            </div>
                            <div class="feed-item-list">
                                <div>
                                    <h5 class="font-size-26 mb-1">Datos del cliente</h5>
                                    <div class="mb-3">
                                        <form>
                                            <div>
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="mb-3">
                                                            <label class="form-label" for="billing-name">Nombre y Apellido</label>
                                                            <input type="text" class="form-control" value={user ? user.name + ' ' + user.lastNames : ''} disabled={'true'} id="billing-name"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="mb-3">
                                                            <label class="form-label" for="billing-email-address">Email</label>
                                                            <input type="email" class="form-control" value={user? user.username : ''} disabled={'true'} id="billing-email-address" placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="mb-3">
                                                            <label class="form-label" for="billing-phone">Teléfono</label>
                                                            <input type="text" class="form-control" value={user? user.phone : ''} disabled={'true'} id="billing-phone" placeholder=""/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-3">
                                                    <label class="form-label" for="billing-address">Dirección</label>
                                                    <textarea class="form-control" id="billing-address" rows="3" value={user ? user.address : ''}  placeholder="" disabled={'true'}></textarea>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="checkout-item">
                            <div class="avatar checkout-icon p-1">
                                <div class="avatar-title rounded-circle bg-primary">
                                    <i class="bx bxs-truck text-white font-size-20"></i>
                                </div>
                            </div>
                            <div class="feed-item-list">
                                <div>
                                    <h5 class="font-size-36 mb-1">Datos de envío</h5>
                                    <div class="mb-3">
                                        <div class="row">
                                          {
                                            formDataShipping.methodShipping == 'RP'?(

                                              <div class="col-lg-12 col-sm-12">
                                                <div data-bs-toggle="collapse">
                                                    <label class="card-radio-label mb-0">
                                                        <input type="radio" name="address" id="info-address1" class="card-radio-input" checked=""/>
                                                        <div class="card-radio text-truncate p-3">
                                                            <span class="fs-14 mb-2 d-block">Retirar personalmente</span>
                                                        </div>
                                                    </label>
                                                    <div class="edit-btn bg-light  rounded">
                                                        <a href="#" data-bs-toggle="tooltip" data-placement="top" title="" data-bs-original-title="Edit">
                                                            <i class="bx bx-pencil font-size-16"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            ): (

                                              <div class="col-lg-12 col-sm-12">
                                                <div data-bs-toggle="collapse">
                                                    <label class="card-radio-label mb-0">
                                                        <input type="radio" name="address" id="info-address1" class="card-radio-input" checked=""/>
                                                        <div class="card-radio text-truncate p-3">
                                                            <span class="fs-14 mb-4 d-block">Enviar a domicilio</span>
                                                            <span class="fs-14 mb-2 d-block">{formDataShipping.name} {formDataShipping.lastNames}</span>
                                                            <span class="text-muted fw-normal text-wrap mb-1 d-block">Dirección: {formDataShipping.address}</span>
                                                           
                                                            <span class="text-muted fw-normal d-block">Tlf: {formDataShipping.phone}</span>
                                                        </div>
                                                    </label>
                                                    <div class="edit-btn bg-light  rounded">
                                                        <a href="#" data-bs-toggle="tooltip" data-placement="top" title="" data-bs-original-title="Edit">
                                                            <i class="bx bx-pencil font-size-16"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>


                                            )
                                          }
                                          
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="checkout-item">
                            <div class="avatar checkout-icon p-1">
                                <div class="avatar-title rounded-circle bg-primary">
                                    <i class="bx bxs-wallet-alt text-white font-size-20"></i>
                                </div>
                            </div>
                            <div class="feed-item-list">
                                <div>
                                    <h5 class="font-size-16 mb-1">Datos de pago</h5>
                                </div>
                                <div>
                                    <h5 class="font-size-14 mb-3">Metodo de pago :</h5>
                                    <div class="row justify-content-center">
                                        <div class="col-lg-6 col-sm-6">
                                            <div data-bs-toggle="collapse">
                                                <label class="card-radio-label">
                                                    <input type="radio" name="pay-method" id="pay-methodoption1" class="card-radio-input"/>
                                                    <span class="card-radio py-3 text-center text-truncate">
                                                        <i class="bx bx-credit-card d-block h2 mb-3"></i>
                                                        Transferencia Bancaria
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card checkout-order-summary">
                <div class="card-body">
                    <div class="p-3 bg-light mb-3">
                        <h5 class="font-size-36 mb-0">Resumen de la orden <span class="float-end ms-2">#MN0124</span></h5>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-centered mb-0 table-nowrap">
                            <thead>
                                <tr>
                                    <th class="border-top-0" style={{"width": "110px"}} scope="col">Producto</th>
                                    <th class="border-top-0" scope="col">Descripción</th>
                                    <th class="border-top-0" scope="col">Precio</th>
                                </tr>
                            </thead>
                            <tbody>

                            { shoppingCart ? 
                               shoppingCart.map((item) => (
                                <tr>
                                    <th scope="row"><img src={item.product.imageUrl} alt="product-img" title="product-img" class="avatar-lg rounded"/></th>
                                    <td>
                                        <h5 class="font-size-16 text-truncate"><a href="#" class="text-dark">{item.product.name}</a></h5>
                                        <p class="text-muted mb-0">
                                            <i class="bx bxs-star text-warning"></i>
                                            <i class="bx bxs-star text-warning"></i>
                                            <i class="bx bxs-star text-warning"></i>
                                            <i class="bx bxs-star text-warning"></i>
                                            <i class="bx bxs-star-half text-warning"></i>
                                        </p>
                                        <p class="text-muted mb-0 mt-1">{configData.currencyFormat} {item.product.unitPrice} x {item.amount}</p>
                                    </td>
                                    <td>{configData.currencyFormat} {item.totalPrice}</td>
                                </tr>

                              ))
                              : null
                              }
                                
                                <tr>
                                    <td colspan="2">
                                        <h5 class="font-size-14 m-0">Sub Total :</h5>
                                    </td>
                                    <td>
                                    {configData.currencyFormat} {shoppingCartTotal.totalPrice}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <h5 class="font-size-14 m-0">Descuento :</h5>
                                    </td>
                                    <td>
                                        - {configData.currencyFormat} 0
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <h5 class="font-size-14 m-0">Costo de envío :</h5>
                                    </td>
                                    <td>
                                    {configData.currencyFormat} 0
                                    </td>
                                </tr>                        
                                    
                                <tr class="bg-light">
                                    <td colspan="2">
                                        <h5 class="font-size-14 m-0">Total:</h5>
                                    </td>
                                    <td>
                                    {configData.currencyFormat} {shoppingCartTotal.totalPrice}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-4">


                <div class="col">
                    <div
                        className="okiro__custom-button shopping__button_products__fields_old"
                    >
                        
                        <button onClick={()=>decreaseStep()}  > Atras </button>
                     </div>
                      <div
                      className="okiro__custom-button shopping__button_products__fields"
                    >
                      <button onClick={()=> generateOrder()} > Generar orden </button>
                    </div>
                </div>

            </div>
    </div>

    </div>
  );
};

export default PreviewGenerateOrder;
