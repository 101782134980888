import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./btnShoppingCart.css";
import { FaShoppingCart } from "react-icons/fa";
import { ShoppingCartContext } from "../../context/ShoppingCartContext";

const BtnShoppingCart = ({ mode, styles }) => {

  const { productsCount } = useContext(ShoppingCartContext);



  return (
    <div
      className="okiro__custom-button"
      
    >
      <Link to={{ pathname: `/shoppingcart` }} className="btn-flotante"><FaShoppingCart /><h6>{productsCount}</h6></Link>
      {/* <a href="/shoppingcart" class="btn-flotante"><FaShoppingCart /><h6>{productsCount}</h6></a> */}
    </div>
  );
};

export default BtnShoppingCart;
