import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useParams } from "react-router";
//import { Link } from "react-router-dom";
import { pageVariants } from "../../../animation/motion";
//import SeeAlso from "../../../components/seealso/SeeAlso";
//import postData from "../../../data/post/post";

import Menu from '../../menu/Menu';

import useFetchProducts from "../../../hooks/useFetchProducts";

import { ProductsContext } from "../../../context/ProductsContext";

import "./productsPage.css";
//import PreviewProducts from "../../../components/preview/PreviewPosts";
import PreviewProducts from "../../../components/preview-product/PreviewProducts";
import BtnShoppingCart from "../../../components/btn-shopping-cart/BtnShoppingCart";

const ProductsPage = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  const {filter,setFilter,products,productsCont} = useContext(ProductsContext);

 // const [chagedCategorieLinks, setChagedCategorieLinks] = useState(false);
  //const [products, setProducts] = useState([]);
  

  const categoryParam = useParams();

  //const {response, filter, setFilter} = useFetchProducts(categoryParam.categoryName);

  const getProducts = () => {

    
   setFilter({...filter, categoriesParam: categoryParam.categoryName});
   // products = setProducts(response.content);

  }

  



  useEffect(() => {

   getProducts();


  },[]);

  let data = [];
  data = productsCont;

  console.log('Dta Products: ',data);


  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="okiro__tagpage"
      style={{"padding-top": "130px"}}
    >
      <Menu categoryPathName={categoryParam.categoryName}/>
      <BtnShoppingCart /> 
      <PreviewProducts postData={data} title="Productos" />
      {/* <div className="okiro__homepage__previewPosts">
        <div className="okiro__hompage__previewPosts-title">

          <svg
            role="img"
            viewBox="0 0 136 24"
            xmlns="http://www.w3.org/2000/svg"
            className="okiro__hompage__previewPosts-zigzag"
          >
            <path d="M1.525 1.525a3.5 3.5 0 014.95 0L20 15.05 33.525 1.525a3.5 3.5 0 014.95 0L52 15.05 65.525 1.525a3.5 3.5 0 014.95 0L84 15.05 97.525 1.525a3.5 3.5 0 014.95 0L116 15.05l13.525-13.525a3.5 3.5 0 014.95 4.95l-16 16a3.5 3.5 0 01-4.95 0L100 8.95 86.475 22.475a3.5 3.5 0 01-4.95 0L68 8.95 54.475 22.475a3.5 3.5 0 01-4.95 0L36 8.95 22.475 22.475a3.5 3.5 0 01-4.95 0l-16-16a3.5 3.5 0 010-4.95z"></path>
          </svg>
        </div>
        <div className="okiro__homepage__previewPosts-posts">
          {window.categoryProducts
            .slice(0, uniqueTagsLength - 1)
            .map((tag, index) => (
              <div className="okiro__post-card" key={tag.tagName + index}>
                <Link to={`/product/${tag.tagName}`}>
                  <div className="okiro__post-card__image">
                    <img src={tag.tagUrl} alt="post-card" />
                  </div>
                  <div className="okiro__post-card__content">
                    <h3>{tag.tagName}</h3>
                    <p>{tag.total} Articulos</p>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div> */}
    </motion.div>
  );
};

export default ProductsPage;
