import HttpResponseEmun from '../helpers/enums/HttpResponseEnum';
import Request from './Request';




export const createUser = async (userObject) => {
  try {
      const response = await Request.post(`/api/v1/users/`, userObject,{},'PRIVATE');
      return response;
  } catch (error) {
      error && console.error(error)
      return error
  }
}

export const updateUser = async (id, userObject) => {
  try {
      const response = await Request.patch(`/api/v1/users/${id}`, userObject,{},'PRIVATE');
      return response;
  } catch (error) {
      error && console.error(error)
      return error
  }
}

export const updatePassword = async (password) => {
  try {
    const response = await Request.post('/users/change-password', { password })
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const recoveryPassword = async (email) => {
  try {
    const response = await Request.post('/public/users/recovery-password', {
      email,
    })
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const unloggedUpdatePassword = async (password, token) => {
  try {
    localStorage.setItem('authorization', token)
    const response = await Request.post('/users/change-password', {
      password,
    })
    localStorage.removeItem('authorization')
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getUserId = async (id) => {
  try {
      const res = await Request.get(`/api/v1/users/${id}`);
      return res;
  } catch (error) {
      if (error) console.error(error);
      return error;
  }
}

export const deleteUserId = async (id) => {
  try {
      const res = await Request.delete(`/api/v1/users/${id}`);
      return res;
  } catch (error) {
      if (error) console.error(error);
      return error;
  }
}

export const getAllUsers = async (filter) => {
  try {
      const res = await Request.get("/api/v1/users", filter);
      return res;
  } catch (error) {
      if (error) console.error(error);
      return error;
  }
}
