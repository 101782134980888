import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { ProductsContext } from "../../context/ProductsContext";
import "./menu.css";

import useFetchCategories from "../../hooks/useFetchCategories";


const Menu = ({categoryPathName}) => {

    const { setFilter } = useContext(ProductsContext);
    const {response} = useFetchCategories();
  
    let data = [];
    let sltCategory = 'TODO';
    data = response.content;

    console.log('categoryPathName: ',categoryPathName);

    if(data && categoryPathName){
      sltCategory = data.map((item) => {
        if( item.pathName == categoryPathName){
          return item.nombre;
        }
      });

    }else{
      sltCategory = 'TODO';
    }


    const handleClick = (ctgDta) =>{
        sltCategory = ctgDta.nombre;
        setFilter({stateParam: 'ACTIVE', showRows: 20,categoriesParam: ctgDta.pathName})
        console.log('Changed Categories: ',ctgDta.nombre);
    };
  


    
    return (
    <>
    <div class="catalog__homepage__menu">
        <div class="catalog__menu">
            <div class="catalog__menu__links">
                <h3 class="side-menu_section-title__2NtpL">Categorías</h3>
                <ul class="side-menu_section-list__2aMqd">
                    <li className="special-link">
                        <a href="/products">
                            {/* <i class="fa fa-check m-">
                                </i> */}
                                Todo</a>
                    </li>

                    { data ? 
                    data.map((item) => (
                    <li className="special-link">
                      <NavLink
                         exact
                         to={{ pathname: `/products/${item.pathName}` }}
                         activeClassName="isActive"
                        onClick={() => handleClick(item)}
                      >
                        {item.nombre}
                      </NavLink>
                    </li>
                    ))

                    : null
                    
                  }

                </ul>
            </div>
        </div>
    </div>

                  

      <div class="dropdown btn_content_ctg">
        {/* <a href="#" class="btn-flotante-ctg">Categorias</a> */}

        <button class="btn btn-flotante-ctg dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
          {sltCategory}
        </button>
        
          <ul  class="dropdown-menu btn-flotante-ctg" aria-labelledby="dropdownMenu2">
          <li className="special-link dropdown-item">
                        <a href="/products">
                            {/* <i class="fa fa-check m-">
                                </i> */}
                                Todo</a>
                    </li>

                    { data ? 
                    data.map((item) => (
                    <li className="special-link dropdown-item">
                      <NavLink
                         exact
                         to={{ pathname: `/products/${item.pathName}` }}
                         activeClassName="isActive"
                        onClick={() => handleClick(item)}
                      >
                        {item.nombre}
                      </NavLink>
                      {/* <button class="dropdown-item" type="button">{item.name}</button> */}
                    </li>
                    ))

                    : null
                    
                  }

          </ul>
        </div>
      


    </>
    );

};


export default Menu;