import HttpResponseEmun from '../helpers/enums/HttpResponseEnum';
import Request from '../services/Request';


export const verifyCode = async (code) => {
    try {
        const res = await Request.get("/api/v1/entry-orders/by-code/" + code);

        if (res.status === HttpResponseEmun.NotFound) {
            return { message: `El código "${code}" está disponible `, unique: true };
        }
        if (res.status === HttpResponseEmun.OK) {
            return { message: "Este código ya existe", unique: false };
        }
    } catch (error) {
        return { message: "Error al validar el código", unique: false };
    }
}

export const createNewOrders = async (orderObject) => {
    try {
        const response = await Request.post(`/api/v1/entry-orders/`, orderObject,{},'PRIVATE');
        return response;
    } catch (error) {
        error && console.error(error)
        return error
    }
}

export const getOrdersId = async (id) => {
    try {
        const res = await Request.get(`/api/v1/entry-orders/${id}`);
        return res;
    } catch (error) {
        if (error) console.error(error);
        return error;
    }
}

export const deleteOrdersId = async (id) => {
    try {
        const res = await Request.delete(`/api/v1/entry-orders/${id}`);
        return res;
    } catch (error) {
        if (error) console.error(error);
        return error;
    }
}

export const getAllOrders = async (filter) => {
    try {
        const res = await Request.get("/api/v1/entry-orders", filter);
        return res;
    } catch (error) {
        if (error) console.error(error);
        return error;
    }
}

export const getAllOrdersByUser = async (id,filter) => {
    try {
        const res = await Request.get(`/api/v1/entry-orders/by-user/${id}`, filter);
        return res;
    } catch (error) {
        if (error) console.error(error);
        return error;
    }
}

