import { Row, Col } from "reactstrap";
import ClientTable from "./ClientsTable";


const ClientsPage = () =>{

    return (
        <Row
        >

            <Col lg="12">
                <ClientTable />
            </Col>

        </Row>
    )
}

export default ClientsPage;