import { Outlet } from "react-router-dom";
import Sidebar from "../container/Sidebar/Sidebar";
import Header from "../container/header/Header";
import { Container } from "reactstrap";

const FullLayout = () => {
  return (
    <main>
      {/********header**********/}
      <Header />
      <div className="pageWrapper d-lg-flex" style={{"padding-top": "100px"}}>
        {/********Sidebar**********/}
        <aside className="sidebarArea shadow" id="sidebarArea" >
          <Sidebar />
        </aside>
        {/********Content Area**********/}
        <div className="contentArea">
          {/********Middle Content**********/}
          <Container className="p-4" fluid>
            <Outlet />
          </Container>
        </div>
      </div>
    </main>
  );
};

export default FullLayout;
