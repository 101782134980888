import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import MdContent from "../../../components/content/MdContent";
import LatestProduct from "../../../components/latest-product/LatestProduct";
import Spinner from "../../../components/spinner/Spinner";
import { pageVariants } from "../../../animation/motion";
import SocialIcon from "../../../components/social/SocialIcon";
import { FaGithubAlt, FaInstagram, FaTwitter } from "react-icons/fa";
import postData from "../../../data/post/post";
import NextPrePost from "../../../components/nextpre/NextPrePost";
import PreviewPosts from "../../../components/preview/PreviewPosts";

import useFetchProduct from "../../../hooks/useFetchProduct";

import "./productPage.css";
import { getProductId } from "../../../services/Products";
import BtnShoppingCart from "../../../components/btn-shopping-cart/BtnShoppingCart";

const ProductPage = () => {
  const [product, setProduct] = useState(null);
  const { id } = useParams();
  let randomArticles = [];


  
  let data = null;

  


  // const getProductId = () =>{

  //   const {response} = useFetchProduct(id);
  //   data = response;
    
  // }


  useEffect(async () => {
    window.scrollTo(0, 0);

    let res = await getProductId(id);

    data = res.data;

    setProduct(res.data);

    // let theArticle = postData.filter((post, index) => {
    //   if (post.id === id) {
    //     setArticleIndex(index);
    //     return post;
    //   } else return "";
    // });

  }, []);

  console.log('data: ', data);


  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      style={{"padding-top": "130px"}}
    >
      <div className="okiro__productpage">
        {product ? (
          <>
            <BtnShoppingCart /> 
            <LatestProduct data={product} showAllTags={true} />
            {/* <MdContent
              content={article.content}
              lock={article.member}
              color={article.color}
            /> */}


          </>
        ) : product ? (
          <Spinner />
        ) : (
          // <div className="okiro__productpage-error">
          //   <div>
            
          //   </div>
          // </div>
          <div><Spinner /></div>
        )}
      </div>

    </motion.div>
  );
};

export default ProductPage;
