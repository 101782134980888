import { Button, Nav, NavItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import user1 from "../../assets/me.jpg";
import probg from "../../assets/contact.jpeg";

import "./sidebar.scss";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useEffect } from "react";

const path ="/dashboard";

const navigation = [
  {
    title: "Pagina pricipal",
    href: "/",
    icon: "bi bi-speedometer2",
    permission: ['SUDO','SELLER', 'CLIENT'],
  },
  {
    title: "Clientes",
    href: path + "/clientes",
    icon: "bi bi-people",
    permission: ['SUDO','SELLER'],
  },
  {
    title: "Permisos",
    href: path + "/",
    icon: "bi bi-card-checklist",
    permission: ['SUDO','SELLER'],
  },
  {
    title: "Ordenes",
    href: path + "/ordenes",
    icon: "bi bi-patch-check",
    permission: ['SUDO','SELLER', 'CLIENT'],
  },
  {
    title: "Pagos",
    href: path + "/",
    icon: "bi bi-credit-card",
    permission: ['SUDO','SELLER', 'CLIENT'],
  }
];

const Sidebar = () => {
  const {user} = useContext(AuthContext);
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };
  let location = useLocation();

  




  useEffect(()=>{

  },[user])






  return (
    <div>
      <div className="d-flex align-items-center"></div>

      <div className="p-3 mt-2">
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            
              user && navi.permission.includes(user.type) ? (

                <NavItem key={index} className="sidenav-bg" >
              <Link
                to={navi.href}
                className={
                  location.pathname === navi.href
                    ? "active nav-link py-3"
                    : "nav-link text-secondary py-3"
                }
              >
                <i className={"d-inline-flex "  + `${navi.icon}`}></i>
                <span className="ms-3 d-inline-block">{navi.title}</span>
              </Link>
            </NavItem>
              
              
                ): null
            
            
          ))}

        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
