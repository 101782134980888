
import PreviewShoppingCart from "../../../components/previewShoppingCart/PreviewShoppingCart";
import { motion } from "framer-motion";
import "./shoppingCartPage.css";

import { GenerateOrderContext } from "../../../context/GenerateOrderContext";
import { useContext } from "react";
import PreviewShippingMethod from "../../../components/previewShippingMethod/PreviewShippingMethod";
import PreviewPaymentMethod from "../../../components/previewPaymentMethod/PreviewPaymentMethod";
import ProgressSteps from "../../../components/ProcessSteps/ProcessSteps";
import PreviewGenerateOrder from "../../../components/previewGenerateOrder/PreviewGenerateOrder";

const ShoppingCartPage = () =>{

    const {step} = useContext(GenerateOrderContext);


    return (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          style={{"padding-top": "100px"}}
        >

            <ProgressSteps></ProgressSteps>

            {
                step == 0 ?(

                    <PreviewShoppingCart></PreviewShoppingCart>

                ) : 
                step == 1 ? (

                    <PreviewShippingMethod></PreviewShippingMethod>

                ) :
                step == 2 ? (

                    <PreviewPaymentMethod></PreviewPaymentMethod>

                ) :
                step == 3 ? (

                    <PreviewGenerateOrder></PreviewGenerateOrder>

                ) : null
            }
            

        </motion.div>
    )

}

export default ShoppingCartPage;