import { useContext, useEffect, useState } from "react";
import { FiTrash } from "react-icons/fi";
import Spinner from "../../../../components/spinner/Spinner";
import { getAllOrders, getAllOrdersByUser } from "../../../../services/Orders";
import { getDateFormat } from "../../../../helpers/utils";
import ProjectTables from "../../../../components/dashboard/ProjectTable";
import { Row, Col, Table, Card, CardTitle, CardBody } from "reactstrap";

import "./previewOrders.css";

import { deleteOrdersId } from "../../../../services/Orders";
import useToasts from "../../../../hooks/useToasts";
import { AuthContext } from "../../../../context/AuthContext";


const PreviewOrders = () =>{
    const {user} = useContext(AuthContext);
    const [filter, setFilter] = useState({stateParam: 'LOADED', showRows: 20});
    const [orders, setOrders] = useState(null);
    const { addToast } = useToasts();



    const deleteOrder = (id) => {

      deleteOrdersId(id);

      addToast({
        type: 'success',
        title: 'Hecho',
        message: 'Orden eliminada con éxito',
      })

      setFilter({stateParam: 'LOADED', showRows: 20});


    }


    useEffect(async () => {
        window.scrollTo(0, 0);
        let res = {};
        if(user && user.type != 'CLIENT'){

          res = await getAllOrders(filter);
          setOrders(res.data.content);

        }else if(user){
          
          res = await getAllOrdersByUser(user.id,filter);
          setOrders(res.data.content);
        }

   
      }, [filter]);




    return (
        <Row
        >
      {/* --------------------------------------------------------------------------------*/}
      {/* table-2*/}
      {/* --------------------------------------------------------------------------------*/}
      <Col lg="12">
        <Card>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <i className="bi bi-card-text me-2"> </i>
            Ordenes
          </CardTitle>
          <CardBody className="">

            <Table bordered>
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Código</th>
                <th scope="col">Fecha</th>
                <th scope="col">Monto</th>
                <th scope="col">Estado</th>
                <th scope="col">Acciones</th>
                </tr>
            </thead>
            <tbody>
                {orders ? (
                
                    orders.map((row, index) => (
                                <tr>
                                <th scope="row">{index+1}</th>
                                <td>P00{row.id}</td>
                                <td>{getDateFormat(row.createdAt)}</td>
                                <td>$ {row.totalPrice}</td>
                                <td>{row.state}</td>
                                <td>
                                  <div className="orders-actions">

                                  <i className={"d-inline-flex btn btn-info bi bi-pencil"}></i> 
                                  <i className={"d-inline-flex btn btn-danger bi bi-trash cursor-pointer"} onClick={()=> deleteOrder(row.id)}></i>
                                    
                                  </div>

                                </td>
                                </tr>
                            ))


                
                    ) : null
                }
              
            </tbody>
            </Table>
            {
             !orders ? (
              <div class="d-flex justify-content-center align-items-center">
                <div class="text-center " >
                  <Spinner />
                </div>
                
             </div>
             ): null
            }

            </CardBody>
        </Card>
      </Col>



        </Row>
    )


}


export default PreviewOrders;