import { useContext, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import CustomButton from "../../../../components/button/CustomButton";
import { AuthContext } from "../../../../context/AuthContext";
import useToasts from "../../../../hooks/useToasts";

import { createUser, getUserId, updateUser } from "../../../../services/users";

const FormsClient = () => {

  const tagParam = useParams();

  const id  = tagParam.id;
  const isAddMode = !id;

  const [formData, setFormData] = useState({
    name: '',
    lastNames: '',
    username: '',
    password: '',
    phone: '',
    email: '',
    address: '',
    photo: '',
    typeDocI: '',
    docI: '',
    type: 'CLIENT',
    roles: [],
    state: 'ACTIVE'

  })
  const {user} = useContext(AuthContext);
  const { addToast } = useToasts();

  const [userEdit, setUserEdit] = useState({})


  const changeForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const infoUserId = async () =>{


    const res = await getUserId(id);

   setUserEdit(res.data);
   setFormData(res.data)

  }


  const cancelForm = () =>{
    window.location.href=process.env.REACT_APP_CONTEXT ? process.env.REACT_APP_CONTEXT+'/dashboard/clientes' : '/dashboard/clientes';
  }



  const handleSubmit = async (e) => {
    //e.preventDefault()
   // setShowLoading(true)

    try {
      if (!(isAddMode ? await createUser(formData) : await updateUser(id, formData))) {
        addToast({
          type: 'error',
          title: 'Upss !!',
          message: isAddMode ? 'Error al crear cliente' : 'Error al actualizar cliente' ,
        })
      } else{

        addToast({
          type: 'success',
          title: 'Hecho',
          message: isAddMode ?  'Cliente creado con éxito' : 'Cliente actualizado con éxito',
        })

       setTimeout(() => {
          window.location.href=process.env.REACT_APP_CONTEXT ? process.env.REACT_APP_CONTEXT+'/dashboard/clientes' : '/dashboard/clientes';
        }, 2000);

       
        
      }
      //setShowLoading(false)
    } catch (error) {
      //setShowLoading(false)
      console.error(error)
    }
  }


  useEffect(()=>{


    if(!isAddMode){

      infoUserId();

    }


  },[])








  return (
    <Row>
      <Col>
        {/* --------------------------------------------------------------------------------*/}
        {/* Card-1*/}
        {/* --------------------------------------------------------------------------------*/}
        <Card>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <i className="bi bi-bell me-2"> </i>
             {isAddMode ? 'Crear Cliente' : 'Editar cliente'}
          </CardTitle>
          <CardBody>
            <Form onChange={changeForm} onSubmit={handleSubmit}>
            <Row>

                <Col md={6}>
                <FormGroup>
                    <Label for="name">Nombre</Label>
                    <Input
                      name="name"
                      type="text"
                      value={userEdit ? formData.name : ''}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                <FormGroup>
                    <Label for="exampleEmail">Apellido</Label>
                    <Input
                      name="lastNames"
                      type="text"
                      value={userEdit ? formData.lastNames : ''}
                    />
                  </FormGroup>
                </Col>


            </Row>

            <Row>
                <Col md={6}>
                    <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      id="exampleEmail"
                      name="username"
                      placeholder="Ej: client@email.com"
                      type="email"
                      value={userEdit && userEdit.username ? formData.username : null}
                    />
                  </FormGroup>
                  
                </Col>

                {/* <Col md={6}>

                    <FormGroup>
                    <Label for="exampleUserName">Nombre de usuario</Label>
                    <Input
                      id="exampleUserName"
                      name="username"
                      placeholder="Ejemplo: usuario46"
                      type="text"
                      value={userEdit ? formData.username : ''}
                    />
                  </FormGroup>
                  
                </Col> */}


            </Row>
            { isAddMode ?(
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="examplePassword">Contraseña</Label>
                  <Input
                    id="examplePassword"
                    name="password"
                    placeholder=""
                    type="password"
                  />
                </FormGroup>
              
              </Col>
            

              <Col md={6}>

                <FormGroup>
                  <Label for="examplePasswordC">Confirmar contraseña</Label>
                  <Input
                    id="examplePasswordC"
                    name="passwordc"
                    placeholder=""
                    type="password"
                  />
                </FormGroup>

              </Col>


            </Row>
            ): null
          }

            <Row>

            <Col md={2}>

            <FormGroup>
                    <Label for="exampleUserName">Tipo de documento</Label>
                    <Input id="selectTypeDocI" name="typeDocI" type="select" value={userEdit && userEdit.typeDocI ? formData.typeDocI : ''}>
                      <option value={'RIF'}>RIF</option>
                      <option value={'CI'}>C.I</option>
                    </Input>
            </FormGroup>

            </Col>

            <Col md={4}>
                <FormGroup>
                    <Label for="exampleUserName">Documento</Label>
                    <Input
                      name="docI"
                      type="text"
                      placeholder="Ej: 25682852"
                      value={userEdit && userEdit.docI ? formData.docI : null }
                    />
                  </FormGroup>
            </Col>

            <Col md={6}>

              <FormGroup>
              <Label for="examplePhone">Teléfono</Label>
              <Input
                id="examplePhone"
                name="phone"
                placeholder="Ej: 0412-7904936"
                type="text"
                value={userEdit && userEdit.phone ? formData.phone : null}
              />
              </FormGroup>

            </Col>

            </Row>
            { user && user.type == 'SUDO' ? (

                <FormGroup>
                <Label for="exampleSelect">Tipo de usuario</Label>
                <Input id="selectType" name="type" type="select">
                  <option value={'CLIENT'}>Cliente</option>
                  <option value={'SELLER'}>Vendedor</option>
                </Input>
                </FormGroup>

               ): null

            }




            </Form>

            <div className="d-flex align-items-center justify-content-center ">

              <div className="okiro__custom-button">
              <button
                   style={{background:'#d0d0d0', border: '2px solid #d0d0d0'}}
                   onClick={()=> cancelForm()}
                   >Cancelar</button> 
              </div>

              <div className="okiro__custom-button" style={{'margin-left': '10px'}}>

              <button
                onClick={()=> handleSubmit()}
                >{ isAddMode ? 'Aceptar': 'Actualizar'}</button>


              </div>

            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default FormsClient;
