import "./processSteps.css";

import { GenerateOrderContext } from "../../context/GenerateOrderContext";
import { useEffect, useState, useContext } from "react";

const ProgressSteps = () =>{

    const [barProgress,setBarProgress] = useState(0);
    const {step} = useContext(GenerateOrderContext);

    let styleButton = "step-button text-center";

   // progress.setAttribute('value', index * 100 /(stepButtons.length - 1) );//there are 3 buttons. 2 spaces.


   const updateProgress = () =>{

        let prog = (step)*100 /(4-1);

        setBarProgress(prog);

   }

   useEffect(()=>{

        updateProgress();

   },[step])


    return (

        <section>
            <div class="container">
                <div class="accordion" id="accordionExample">
                    <div class="steps">
                        <progress id="progress" value={barProgress} max="100" ></progress>
                        <div class="step-item">
                            <button className="step-button text-center " type="button" data-toggle="collapse"
                                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                1
                            </button>
                            <div class="step-title">
                                Verificar carrito
                            </div>
                        </div>
                        <div class="step-item">
                            <button className={ step > 0 ? ("step-button text-center collapsed done") : ("step-button text-center collapsed" )} type="button" data-toggle="collapse"
                                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                2
                            </button>
                            <div class="step-title">
                                Datos de envío
                            </div>
                        </div>
                        <div class="step-item">
                            <button className={ step > 1 ? ("step-button text-center collapsed done") : ("step-button text-center collapsed" )} type="button" data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                3
                            </button>
                            <div class="step-title">
                                Datos de pago
                            </div>
                        </div>
                        <div class="step-item">
                            <button className={ step > 2 ? ("step-button text-center collapsed done") : ("step-button text-center collapsed" )} type="button" data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                4
                            </button>
                            <div class="step-title">
                                Generar orden
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </section>

    );

};




export default ProgressSteps;