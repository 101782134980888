import  logo  from "../assets/images/logoM.png";
import loadImgAnimate from "../assets/images/load.gif";


const configData =
    {
      company:{
        name: 'Mercantil Fabuloso',
        rif: 'j65498446-2'
      },
      logo: {logo},
      currencyFormat: "USD",
      defaultImgAnimate: {loadImgAnimate},
      decimal: 2,
      footer: {

      }
    };
  

export default configData;
  