import React, { useContext } from "react";
import { Link } from "react-router-dom";

import configData from "../../config/configData";
import { ShoppingCartContext } from "../../context/ShoppingCartContext";
import useToasts from "../../hooks/useToasts";

import imgAddCart from "../../assets/shopping-cart-add.svg";

import "./postCard.css";

const ProductCard = ({ post }) => {
  const { id, name, code, unitPrice, imageUrl, category} = post;
   
  const { addToast } = useToasts();

  const {setShoppingCart,addShoppingCart,decreaseShoppingCart,productsCount} = useContext(ShoppingCartContext);

  let amount = 1;


  const addProdShoppingCart = () => {
    if(addShoppingCart(post,amount)){
      addToast({
        type: 'success',
        title: 'Hecho',
        message: 'Artículo agregado al carrito',
      })
    }else{

      addToast({
        type: 'success',
        title: 'Hecho',
        message: 'Artículo ya en el carrito',
      })

    }
  }


  return (
    <div className="okiro__product-card" >
      <div className="okiro__product-card__image">
        <Link to={{ pathname: `/product/${id}` }}>
          { imageUrl ? (
             <img src={imageUrl} alt="post-card" />
          ) 
          : <img src='https://cloud-s5.fyslink.com/api-catalog-bk/api/v1/public/files/temp/afec417c-a96a-45eb-8533-d66d7c287273.webp' alt="post-card" />

          }
         
        </Link>
        <div className="msg_texto">
          <h4><strong>-10%</strong></h4>
        </div>
      </div>
      <div className="okiro__product-card__content">
        <Link to={{ pathname: `/product/${id}` }}>
          <h3><div><i class="fa fa-star start-sty"></i> {name}</div> </h3>
        </Link>
        <Link to={`/`}>
          <div class="name-category">
           <p >{category.nombre}</p>
          </div>
        
        </Link>
      </div>
      <div class="product-bottom-details">
				<div class="product-price">{configData.currencyFormat} {unitPrice}</div>
				<div class="product-links">
					{/* <a href="" class="ml-2"><i class="fa fa-heart"></i></a> */}
          
					<a className="add__Cart" onClick={()=> addProdShoppingCart()} title="Añadir al carrito"><img class="add__Cart" width="35" height="30" src={imgAddCart}></img></a>
				</div>
			</div>
    </div>
  );
};

export default ProductCard;
